import React from 'react'
import styled from '@emotion/styled'
import { below } from '../../utils/media-query'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { previewHoverEffect } from '../../styles'
const RelatedArticlesContainer = styled.div`
  width: 50%;
  padding-left: 30px;
  border-left: solid 1px rgba(160, 160, 160, 0.3);
  height: 502px;
  ${below.med`
    padding: 0;
    padding-top: 21px;
    width: 100%;
    display: none;
  `}
`

const Title = styled.p`
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #181a1b;
  margin-bottom: 24px;
  ${below.small`
    font-size: 20px;
    margin-bottom: 12px;
  `}
`

const ArticlePreview = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  ${previewHoverEffect}
  ${below.small`
    margin-bottom: 8px;
  `}
`

const VerticalArticlesList = styled.div`
  display: flex;
  flex-direction: column;
`

const ArticlePreviewImage = styled(Img)`
  width: 8.125rem;
  min-width: 8.125rem;
  height: 8.125rem;
  ${below.small`
    height: ${18 * 0.35}rem;
    padding: 0;
  `}
`

const ArticlePreviewTitle = styled.h2`
  padding: 15px;
  padding-top: 0;
  margin-bottom: 0;
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #181a1b;
  ${below.small`
    margin-right: 0.5rem !important;
    font-size: 14px;
  `}
`

const TrendingTopics = ({ articles = [], title }) => {
  return (
    <RelatedArticlesContainer>
      <Title>{title}</Title>
      <VerticalArticlesList>
        {articles.map((article, idx) => (
          <Link to={article.slug} key={idx}>
            <ArticlePreview>
              {article?.previewImage &&
                <ArticlePreviewImage
                  fluid={article.previewImage.childImageSharp.fluid}
                />}
              <ArticlePreviewTitle>{article.title}</ArticlePreviewTitle>
            </ArticlePreview>
          </Link>
        ))}
      </VerticalArticlesList>
    </RelatedArticlesContainer>
  )
}

export default TrendingTopics
