import browserCookie from 'browser-cookies'
import qs from 'qs'
import axios from 'axios'

import {
  adverseUrl,
  MILLISECONDS_1_WEEK,
  ORIGIN_KEY,
  ORIGIN_PARAM,
  STRATEGIS_END_POINT
} from './constants'
import addCTAListeners from './addCTAListeners'
import { isWindow } from '../utils/commonUtil'

const createOffer = (queryParams, type, scriptInfo) => {
  const qsa = document.querySelectorAll.bind(document)
  const script = document.createElement('script')
  // rebuild script data with secondary origin if required
  const scriptData = { ...scriptInfo, origin: getOriginId(scriptInfo.origin) }
  const isMobile = () => {
    return window.innerWidth < 500
  }
  document.querySelectorAll = (sel) => {
    if (sel !== 'script') return qsa(sel)
    document.querySelectorAll = qsa
    return [script]
  }

  if (type === 'cta') {
    fillScriptData(queryParams, type, script, isMobile, scriptData)
    document.querySelector('body').appendChild(script)
  } else {
    window.offersTarget.appendChild(
      fillScriptData(queryParams, type, script, isMobile)
    )
  }
}

const fillScriptData = (queryParams, type, script, isMobile, scriptInfo) => {
  script.src = adverseUrl
  script.dataset.renderMode = isMobile() ? 'mobile' : 'native'
  script.dataset.adFeedCount = '4'
  script.dataset.decisionTreeId = queryParams.decisionTreeId
  if(!scriptInfo.templateId) delete scriptInfo.templateId

  switch (type) {
    case 'mn':
      script.dataset.zoneid = queryParams.zoneId || 'yoo1a0'
      script.dataset.originId = queryParams.originId || '19876'
      script.dataset.creditScore = queryParams.creditScore || 'unknown'
      break
    case 'cta':
      script.dataset.manualRender = true
      script.onload = () => {
        const target = document.querySelectorAll('.offersTarget')
        const config = { childList: true }
        const observer = new MutationObserver((e) => {
          e[0].target.querySelectorAll('a').forEach((node) => {
            addCTAListeners(node, ['converted-users'])
          })
        })
        // wait for strategis if there is campaignId in the query string
        const params = isWindow ? qs.parse(window.location.search.slice(1)) : {}
        const shouldWaitForStrategis = Object.keys(params).find(
          key => key === 'campaignId')
        if (shouldWaitForStrategis) {
          return axios({
            method: 'GET',
            url: `${STRATEGIS_END_POINT}${window.location.search}`,
            headers: {
              'Content-type': 'application/json'
            }
          })
            .then(({ data: strategisResponse }) => {
              // remove prefixes, since options are passed to lincx api
              // through window.adverse, and not through query string params
              const prefixes = ['adverse-', 'lincx-']
              const transformedResponse = {}
              let prefix
              Object.entries(strategisResponse).forEach(([key, value]) => {
                prefix = prefixes.find(prefix => key.startsWith(prefix))
                prefix &&
                  (transformedResponse[key.replace(prefix, '')] = strategisResponse[key])
                !prefix && (transformedResponse[key] = value)
              })
              const updatedScriptInfo = { ...scriptInfo, ...transformedResponse }
              target.forEach((node) => {
                observer.observe(node, config)
                window.adverse(node, updatedScriptInfo)
              })
            })
            .catch((err) => {
              return Promise.reject(err)
            })
        }
        target.forEach((node) => {
          observer.observe(node, config)
          window.adverse(node, scriptInfo)
        })
      }
    // eslint-disable-next-line
    default:
      // is this default fall though intended ?
      script.dataset.zoneid = queryParams.zoneId || 'a9xaqu'
      script.dataset.originId = queryParams.originId
      break
  }
  return script
}

/**
 * takes default origin id and returns modified origin Id.
 *
 * @param {*} defaultOrigin
 */
function getOriginId (defaultOrigin) {
  const params = qs.parse(window.location.search.slice(1))
  const originId = params[ORIGIN_PARAM]
  let secondaryOriginId = null
  if (originId) {
    // if originId is present in query params, store it for future use
    const originStoreVal = originId.split('_')[0]
    window[ORIGIN_KEY] = originStoreVal
    browserCookie.set(ORIGIN_KEY, originStoreVal, {
      expires: new Date(Date.now() + MILLISECONDS_1_WEEK),
      samesite: 'Strict'
    })
  } else {
    // if originId is not present in url,
    // pull out from window or cookie which we may have if user visited in past
    secondaryOriginId = window[ORIGIN_KEY] || browserCookie.get(ORIGIN_KEY)
  }

  return secondaryOriginId ? `${defaultOrigin}_${secondaryOriginId}` : defaultOrigin
}

export default createOffer
