import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import qs from 'qs'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import createOffer from '../utils/createOffer'
import { isWindow, EXP_MODE, getVariant } from '../utils/commonUtil'

import addCTAListeners from '../utils/addCTAListeners'
import setGeoTags from '../utils/geoFetch'

import RelatedArticles from '../components/blogPage/RelatedArticles'

import { BlogPostLayout } from '../components/blogPage/BlogPageComponents'
import Post from '../components/postPage/Post'
import { getQueryFields } from '../utils/helpers'

export class BlogPostTemplate extends React.Component {
  constructor (props) {
    super(props)
    const params = isWindow ? qs.parse(window.location.search.slice(1)) : {}
    this.state = {
      isMounting: true,
      hideAds: params['no-display'] && params['no-display'] !== 'false',
      variant: EXP_MODE.DEFAULT
    }
  }

  componentDidMount () {
    const variant = getVariant(this.props.alphaTest, this.props.betaTest)
    this.setState({
      isMounting: false,
      variant: variant
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.isMounting) {
      let {
        zoneId,
        campaign,
        defaultZoneId,
        defaultCampaignHost,
        defaultOrigin,
        lincxTemplateId,
        alphaTest,
        betaTest,
        title
      } = this.props

      let templateId = lincxTemplateId
      let s2 = 'Default'
      switch (this.state.variant) {
        case EXP_MODE.ALPHA:
          defaultCampaignHost =
            alphaTest.defaultCampaignHost || defaultCampaignHost
          campaign = alphaTest.campaign || campaign
          templateId = alphaTest.lincxTemplateId || lincxTemplateId
          s2 = (alphaTest.s2 && alphaTest.s2.trim()) || 'Alpha'
          break
        case EXP_MODE.BETA:
          defaultCampaignHost =
            betaTest.defaultCampaignHost || defaultCampaignHost
          campaign = betaTest.campaign || campaign
          templateId = betaTest.lincxTemplateId || lincxTemplateId
          s2 = (betaTest.s2 && betaTest.s2.trim()) || 'Beta'
          break
        default:
          s2 = 'Default'
        // do nothing
      }

      const zoneInfo = zoneId || defaultZoneId || ''
      zoneInfo &&
        campaign &&
        createOffer('', 'cta', {
          zoneId: zoneInfo,
          tag: campaign,
          domain: defaultCampaignHost,
          origin: defaultOrigin,
          s1: title,
          s2: s2,
          templateId: templateId
        })
      const tags = [...this.props.tags]
      tags.push('converted-users')
      setGeoTags()
      const offerButtons = Array.from(
        document.getElementsByClassName('offerButton')
      )
      const multiOfferButtons = Array.from(
        document.getElementsByClassName('multi-ad__btn')
      )
      offerButtons.concat(multiOfferButtons).forEach(el => {
        addCTAListeners(el, tags)
      })
    }
  }

  render () {
    let {
      title,
      banner,
      campaign,
      alphaTest,
      betaTest,
      description
    } = this.props

    const { isMounting, hideAds } = this.state
    if (!isMounting) {
      switch (this.state.variant) {
        case EXP_MODE.ALPHA:
          title = alphaTest.title || title
          banner = alphaTest.banner || banner
          campaign = alphaTest.campaign || campaign
          description = alphaTest.description || description
          break
        case EXP_MODE.BETA:
          title = betaTest.title || title
          banner = betaTest.banner || banner
          campaign = betaTest.campaign || campaign
          description = betaTest.description || description
          break
        default:
        // do nothing
      }
    }

    return (
      <Post
        extended
        article={
          {
            ...this.props,
            title,
            banner,
            campaign,
            isMounting,
            hideAds,
            description,
            featureTagColor: '#ffab32'
          }
        }
      />
    )
  }
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.bool
}

const BlogPost = props => {
  const { markdownRemark: post } = props.data

  const {
    description,
    date,
    author,
    adButtonText,
    adButtonUrl,
    banner,
    banner2,
    tags,
    authorPicture,
    zoneId,
    campaign,
    defaultOrigin,
    defaultCampaignHost,
    lincxTemplateId,
    alphaTest,
    betaTest,
    category,
    showAds
  } = post.frontmatter
  const { body2Html, resources } = post.fields

  const defaultZoneId =
    props.data.zoneIdInfo.frontmatter.ADVERSE_DEFAULT_ZONE_ID

  const renderRelated = props => {
    if (!props.data.allMarkdownRemark) return

    const { data } = props
    const { nodes: posts } = data.allMarkdownRemark

    const articlesMapped = getQueryFields(posts)
    return (
      <RelatedArticles
        articles={articlesMapped}
        title='Related Articles'
      />
    )
  }

  return (
    <Layout IsBlogPage={!!props.data.allMarkdownRemark}>
      <BlogPostLayout>
        <BlogPostTemplate
          resources={resources}
          content={post.html}
          contentComponent={HTMLContent}
          description={description}
          date={date}
          author={author}
          adButtonText={adButtonText}
          adButtonUrl={adButtonUrl}
          banner={banner}
          banner2={banner2}
          authorPicture={authorPicture}
          pathname={props.location.pathname}
          zoneId={zoneId}
          defaultZoneId={defaultZoneId}
          campaign={campaign}
          defaultOrigin={defaultOrigin}
          defaultCampaignHost={defaultCampaignHost}
          lincxTemplateId={lincxTemplateId}
          helmet
          tags={tags}
          title={post.frontmatter.title}
          alphaTest={alphaTest}
          betaTest={betaTest}
          category={category}
          adsense={showAds ?? false}
          body2Html={body2Html}
          snippet={post.frontmatter.snippet}
          location={props.location}
        />
        {renderRelated(props)}
      </BlogPostLayout>
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $country: String, $tags: [String]) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        body2Html
        resources
      }
      frontmatter {
        # adButtonText
        # adButtonUrl
        # zoneId
        ...BasicPostFields
        banner {
          ...PostBanner
        }
        banner2 {
          ...PostBanner
        }
        alphaTest {
          ...AlphaBetaTest
        }
        betaTest {
          ...AlphaBetaTest
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { tags: { in: $tags }, country: { eq: $country } }
        id: { ne: $id }
      }
      limit: 2
    ) {
      nodes {
        fields {
          slug
          body2Html
          thumbnail {
            ...TooSmallThumbnail
          }
        }
        frontmatter {
          title
        }
      }
    }
    zoneIdInfo: markdownRemark(frontmatter: { ADVERSE_TOKEN: { ne: null } }) {
      frontmatter {
        ADVERSE_DEFAULT_ZONE_ID
      }
    }
  }
`
